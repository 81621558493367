/**
 * 房间权限逻辑
 */
import { menusEvent } from "vue3-menus";
import { useRoomNote } from "@/im/message-handler.js";

export function useMenus() {
    const store = appStore();
    const { currentGroupID, currentRoomID, currentRoomInfo, seatInfoList, userId, myRole, mySeatInfo, isOnSeat, isOnCloseMic, chatRoomType,roleMap } = storeToRefs(store);
    const { emitter } = useRoomNote();
    //管理列表
    const adminSet = computed(() => (currentRoomInfo.value.adminSet ? currentRoomInfo.value.adminSet.split(",") : []));

    //超管列表
    const superAdminSet = computed(() => (currentRoomInfo.value.superAdminSet ? currentRoomInfo.value.superAdminSet.split(",") : []));

    //上麦方式 //0-申请上麦 | 1-自动上麦
    const microphoneWay = computed(() => currentRoomInfo.value.microphoneWay);
    
    //申请上麦图标显示类型 //0-申请上麦、1申请列表
    const applyMicType = computed(() => {
        if (myRole.value > 0) return 1;// && isOnSeat.value
        else return 0;
    });

    //相亲房vip提示弹窗
    const isShowVipDialog = ref(false);

    //相亲房进度
    const blindDateType = computed(() => (currentRoomInfo.value && currentRoomInfo.value.blindDateType) || 0);

    //监听被拒绝上麦消息
    emitter.once("adminRejectMicrophone", ({ msg }) => {
        if (msg.userId == store.userId) {
            Toast(msg.description);
        }
    });
    //监听邀请上麦消息
    /*emitter.once("CtInviteMic", ({ msg }) => {
        if (msg.processId && Number(msg.processId) === store.userId) {
            const { microphoneNum } = msg;
            let timeDown = 10;
            const cancelButtonText = ref("拒绝（" + timeDown + "s)");
            const interId = setInterval(() => {
                if (timeDown < 2) {
                    clearInterval(interId);
                    Dialog.close();
                    return;
                }
                timeDown--;
                cancelButtonText.value = "拒绝（" + timeDown + "s)";
            }, 1000);

            Dialog.confirm({
                title: "提示",
                message: "当前主持正在邀请您上麦",
                confirmButtonText: "同意",
                cancelButtonText: cancelButtonText,
                className: "confirm-dialog",
                closeOnClickOverlay: true,
                lockScroll: false,
                teleport: "body",
            })
                .then(async () => {
                    try {
                        const resp = await fly.getOnMicrophone({ roomid: currentRoomID.value, type: 0, microphoneNum });
                        if (resp.code === 10049) Toast.fail(resp.msg);
                    } catch (error) {
                        console.error("error:", error);
                    }
                })
                .catch(() => {})
                .finally(() => {
                    clearInterval(interId);
                });
        }
    });*/

    // //对某人是否有操作权限
    // function hasHandlePower(userId) {
    //     if (!userId) return false;
    //     let otherRole;
    //     if (currentRoomInfo.value.uid && currentRoomInfo.value.uid === Number(userId)) otherRole = 3;
    //     else if (superAdminSet.value.includes(String(userId))) otherRole = 2;
    //     else if (superAdminSet.value.includes(String(userId))) otherRole = 1;
    //     else otherRole = 0;
    //     return myRole.value > otherRole;
    // }
    
    //对某人是否有操作权限
    function hasHandlePower(info) {
        if (!info.role) return false;
        //console.log('操作权限 ：',info,myRole.value);
        if(myRole.value==1 && myRole.value > roleMap.value[info.role]){//普通主持不能进行抱下麦操作
            return false;
        }
        //权限是管理员以上，并且是在主持位，可以对同级别及以下人进行操作
        if(myRole.value>= 2 && myRole.value >= roleMap.value[info.role] && seatInfoList.value[0].uid==userId.value){ 
            return true;
        }
        return myRole.value > roleMap.value[info.role];
    }

    //点击申请上麦图标
    async function onApplyMic() {
        //申请上麦
        if (applyMicType.value === 0 && isOnSeat.value) {
            Toast("您已在麦位上");
            return;
        }
        //显示申请列表

        store.$patch(state => {
            state.openApplyListDialog = true;
        });
    }

    //点击麦位打开不同类型菜单
    function onClickSeat(event, seatInfo, index) {
        /*if (seatInfo.uid && seatInfo.uid != store.userId) {//麦位有人不是自己时显示选中
            seatInfo.clicked = true
        }*/
        // console.log('当前麦位：',seatInfo,index);
        seatInfo = { ...seatInfo, index };
        let menuArr = [];
        //是否管理以上权限
        if (myRole.value > 0) {
            const LOCK_MiC = seatInfo.closeMicrophone ? "取消闭麦位" : "设置闭麦位";
            let BOSS_LOCATION = seatInfo.bossLocation ? "取消老板位" : "设置老板位";

            const CLEAR_SCORE = "清空礼物值"

            //派单厅,相亲厅去除老板位操作
            if(chatRoomType.value === 2 || chatRoomType.value === 3){
                BOSS_LOCATION = "";
            }
            //是否麦位有人
            if (seatInfo.uid) {
                //是否是自己

                if (seatInfo.uid == store.userId) {
                    menuArr = ["查看资料", "下麦", LOCK_MiC, BOSS_LOCATION,CLEAR_SCORE];
                } else {
                    menuArr = ["查看资料", hasHandlePower(seatInfo) ? "抱下麦" : "", LOCK_MiC, BOSS_LOCATION,CLEAR_SCORE];
                }
            } else {
                if (seatInfo.lockMicrophone) {
                    menuArr = ["取消锁麦"];
                } else {
                    menuArr = ["上麦", "锁麦", LOCK_MiC, BOSS_LOCATION];
                }
            }
        } else {
            //是否麦位有人
            if (seatInfo.uid) {
                //是否是自己
                if (seatInfo.uid == store.userId) {
                    menuArr = ["查看资料", "下麦"];
                } else {
                    menuArr = ["查看资料"];
                }
            } else {
                if (seatInfo.lockMicrophone) {
                    Toast("麦位已锁");
                } else {
                    if(chatRoomType.value === 3 && index===9){//相亲厅vip位单独处理
                        menuArr = ["上麦"];
                    }else{
                        menuArr = ["申请上麦"];
                    }
                }
            }
        }
        //相亲房增加揭面和揭示心动
        if (menuArr.length) {
            //相亲房，并且是主持人
            if (chatRoomType.value === 3 && seatInfoList.value.indexOf(mySeatInfo.value) === 0) {
                // if (blindDateType.value === 2 && seatInfo.isShowFace === 0 && seatInfo.uid !== store.userId) {
                    //相遇相知阶段，未揭面，非自己
                    // menuArr.unshift("揭面");
                // }
                if (blindDateType.value === 3 && seatInfo.redisBlindDateChooseDto && seatInfo.isShowChoose == 0) {
                    menuArr.unshift("揭示心动");
                }

            }

            menusEvent(event, filterMenus(menuArr), seatInfo);
            event.preventDefault();
        }
    }

    // function publish() {}

    //麦位菜单事件列表
    const menusEventList = [
        // {
        //     label: "揭面",
        //     click: async (e, seatInfo) => {
        //         const microphoneNum = seatInfoList.value.findIndex(item => seatInfo.uid === item.uid);
        //         if (microphoneNum === -1) return;
        //         try {
        //             const { code } = await fly.postBlindDateShow({
        //                 roomId: currentRoomID.value,
        //                 microphoneNum,
        //             });
        //         } catch (error) {
        //             console.log("error :>> ", error);
        //         }
        //     },
        // },
        {
            label: "揭示心动",
            click: async (e, seatInfo) => {
                const microphoneNum = seatInfoList.value.findIndex(item => seatInfo.uid === item.uid);
                if (microphoneNum === -1) return;
                try {
                    const { code } = await fly.postBlindDateShowChoose({
                        roomId: currentRoomID.value,
                        microphoneNum,
                    });
                } catch (error) {
                    console.log("error :>> ", error);
                }
            },
        },
        {
            label: "查看资料",
            click: (e, seatInfo) => {
                store.openHeadProfile = seatInfo;
            },
        },

        {
            label: "上麦",
            click: async (e, seatInfo) => {
                try {
                    const type = isOnSeat.value ? 2 : 0;
                    const resp = await fly.getOnMicrophone({ roomid: currentRoomID.value, type, microphoneNum: seatInfo.index });//type: 0
                    if (resp.code === 10049) Toast.fail(resp.msg);
                    if (resp.code === 10057 && chatRoomType.value === 3) {//相亲厅vip座位显示上麦规则
                        isShowVipDialog.value = true;
                    }
                } catch (error) {
                    console.error("error:", error);
                }
            },
        },
        {
            label: "申请上麦",
            click: async (e, seatInfo) => {
                if (microphoneWay.value === 1) {
                    //自由上麦
                    const event = menusEventList.find(item => item.label === "上麦");
                    event.click(e, seatInfo);
                } else {
                    onApplyMic();
                }
            },
        },
        {
            label: "下麦",
            click: async (e, seatInfo) => {
                try {
                    await fly.getOnMicrophone({ roomid: currentRoomID.value, type: 1, microphoneNum: seatInfo.index });
                    myRole.value = roleMap.value[currentRoomInfo.value.role];//恢复默认进房权限
                } catch (error) {
                    console.error("error:", error);
                }
            },
        },
        {
            label: "抱下麦",
            click: async (e, seatInfo) => {
                try {
                    await fly.getOnMicrophone({ roomid: currentRoomID.value, type: 1, microphoneNum: seatInfo.index });
                } catch (error) {
                    console.error("error:", error);
                }
            },
        },
        {
            label: "锁麦",
            click: async (e, seatInfo) => {
                try {
                    await fly.getSetLockMicrophone({ roomid: currentRoomID.value, status: 1, microphoneNum: seatInfo.index });
                } catch (error) {
                    console.log("error :>> ", error);
                }
            },
        },
        {
            label: "取消锁麦",
            click: async (e, seatInfo) => {
                try {
                    await fly.getSetLockMicrophone({ roomid: currentRoomID.value, status: 0, microphoneNum: seatInfo.index });
                } catch (error) {
                    console.log("error :>> ", error);
                }
            },
        },
        {
            label: "设置闭麦位",
            click: async (e, seatInfo) => {
                try {
                    await fly.postCloseMicrophone({ roomId: currentRoomID.value, type: 1, microphoneNum: seatInfo.index });
                } catch (error) {
                    console.log("error :>> ", error);
                }
            },
        },
        {
            label: "取消闭麦位",
            click: async (e, seatInfo) => {
                try {
                    await fly.postCloseMicrophone({ roomId: currentRoomID.value, type: 0, microphoneNum: seatInfo.index });
                } catch (error) {
                    console.log("error :>> ", error);
                }
            },
        },
        {
            label: "设置老板位",
            click: async (e, seatInfo) => {
                try {
                    await fly.getSetBossLocation({ roomid: currentRoomID.value, status: 1, microphoneNum: seatInfo.index });
                } catch (error) {
                    console.log("error :>> ", error);
                }
            },
        },
        {
            label: "取消老板位",
            click: async (e, seatInfo) => {
                try {
                    await fly.getSetBossLocation({ roomid: currentRoomID.value, status: 0, microphoneNum: seatInfo.index });
                } catch (error) {
                    console.log("error :>> ", error);
                }
            },
        },
        {
            label: "清空礼物值",
            click: async (e, seatInfo) => {
                // console.log('麦位信息：===>',seatInfo);return;
                try {
                    await fly.postClearGiftScore({ roomId: currentRoomID.value, bid: seatInfo.uid});
                } catch (error) {
                    console.log("error :>> ", error);
                }
            },
        },
    ];

    function filterMenus(arr) {
        return { menus: menusEventList.filter(item => arr.includes(item.label)) };
    }

    return {
        superAdminSet,
        microphoneWay,
        applyMicType,
        isShowVipDialog,
        onClickSeat,
        onApplyMic,
    };
}
export function useRightMenus(){
    const store = appStore();
    const menuList = {
        menus: [
            {
                label: "置顶",
                click(e,item) {
                    store.timHandle.pinConversation({ conversationID:item.conversationID, isPinned: !item.isPinned });
                },
            },
            {
                label: "删除",
                click(e,{ conversationID }) {
                    store.timHandle.deleteConversation(conversationID);
                },
            },
        ],
        zIndex: 111,
    };
    //私聊消息右键操作
    function onContextMenu(event, item) {
        menuList.menus[0].label = item.isPinned ? '取消置顶' : '置顶'
        menusEvent(event, menuList, item);
        event.preventDefault();
    }
    return {
        onContextMenu
    }
}