<script setup>
import { useEmitter } from "@/im/message-handler.js";

const router = useRouter();

const store = appStore();

const { systemMessageList } = storeToRefs(store);

const { emitter } = useEmitter();
emitter.once("charRoomInvite", ({ msg }) => {
    if (msg.inviteType !== 1) return;
    msg.id = "" + msg.random + Math.floor(Math.random() * 1e10);
    systemMessageList.value.push(msg);
    setTimeout(() => {
        const index = systemMessageList.value.indexOf(msg);
        if (-1 !== index) {
            systemMessageList.value.splice(index, 1);
        }
    }, 6000);
});

// function add() {
//     let message = {
//         charmLevel: 45,
//         wealthLevel: 33,
//         inviteType: 1,
//         fansCount: 2,
//         userId: 1012394,
//         roomId: 15097,
//         mType: "charRoomInvite",
//         random: "dci0s2bo7b",
//         nickname: "今天晴2",
//         headPortrait: "https://oss.cdddian.com/im/2022-10-14/0f3c576b10f3493dbcb70cff703613b7895743.png",
//     };
//     if (message.inviteType !== 1) return;
//     message = { ...message, id: message.random + Math.floor(Math.random() * 1e10) };
//     systemMessageList.value.push(message);
//     setTimeout(() => {
//         const index = systemMessageList.value.indexOf(message);
//         if (-1 !== index) {
//             systemMessageList.value.splice(index, 1);
//         }
//     }, 6000);
// }
// window.add = add;


function joinNow(roomId) {
    if (roomId && store.currentRoomID != roomId) {
        const { href } = router.resolve(`/chat-room/${roomId}`);
        window.open(href, "_self");
    }
}
</script>

<template>
    <Teleport to="body">
        <ul class="push-message">
            <transition-group name="message-tips">
                <template v-for="(item, index) in systemMessageList" :key="item.id">
                    <li v-if="item.mType === 'charRoomInvite'" class="char-room-invite">
                        <img :src="item.headPortrait" alt="" />
                        <div class="content">
                            <p>{{ item.nickname }}</p>
                            <p>邀请您加入「语音派对」</p>
                        </div>
                        <p class="join-btn" @click="joinNow(item.roomId)">立即加入</p>
                    </li>
                </template>
            </transition-group>
        </ul>
    </Teleport>
</template>
<style lang="less" scoped>
.message-tips-move,
.message-tips-enter-active,
.message-tips-leave-active {
    transition: all 0.5s ease;
}
.message-tips-enter-from,
.message-tips-leave-to {
    transform: translateY(-100%);
    opacity: 0;
}
.message-tips-leave-active {
    position: absolute;
}

.push-message {
    // width: 1000px;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 11;
    transform: translateX(-50%);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    li {
        margin-top: 10px;
        border-radius: 8px;
        padding: 15px 25px;
        // background: #292940;
        background: #2d2d68;
        &:last-child {
            &.message-tips-leave-active {
                position: relative;
            }
        }
        &.char-room-invite {
            display: flex;
            align-items: center;
            img {
                .rect(40px);
                border-radius: 8px;
            }
            .content {
                margin: 0 35px 0 15px;
                p {
                    font-size: 14px;
                    line-height: 1.6;
                    &:last-child {
                        font-size: 12px;
                        color: #999;
                    }
                }
            }
            .join-btn {
                font-size: 14px;
                color: white;
                background: linear-gradient(90deg, #a283f9 0%, #7b4dfd 100%);
                padding: 2px 20px;
                border-radius: 20px;
                .click-btn();
            }
        }
    }
}
</style>
