<script setup>
import { isEmpty, isPhone, getFingerPrint, randomString } from "@/utils";
const router = useRouter(),
    store = appStore(),
    state = reactive({
        mobile: "",
        password: "",
        code: "",
        isDisabled: false,
        loading: false,
        replayCheck: false,
        isShowBannedTip: false,
        tipText: null,
        phoneMsg: "",
        codeMsg: "",
        signType: 0, //默认验证码登录
        codeText: "获取验证码",
    });

watch(
    () => state.signType,
    () => {
        state.password = "";
        state.codeMsg = "";
    }
);

async function handlerLogin(info) {
    store.setUserInfo(info);
    await store.updateUserInfo();
    fly.postLoginVersion({appVersion:'pc'})
    state.loading = false;
    state.replayCheck = false;
    store.openLoginDialog = false;
    // router.replace("/");
}
async function login() {
    if (state.loading) return false;
    state.loading = true;
    try {
        const deviceCode = await getFingerPrint();
        let resp;
        if (state.signType === 0 || state.replayCheck) {
            resp = await fly.postCodeLogin({ mobile: state.mobile, code: state.code, deviceCode }); //验证码登录
        } else if (state.signType === 1) {
            resp = await fly.postLogin({ mobile: state.mobile, password: state.password, deviceCode }); //密码登录
        } else if (state.signType === 2) {
            resp = await fly.postUpdatePassword({ mobile: state.mobile, code: state.code, newPassword: state.password }); //忘记密码
        }
        const { code, data, msg } = resp;
        
        if (code === 0) {
            if (data) {
                handlerLogin(data);
            }
            if (state.signType === 2) {
                state.signType = 1;
                Toast.success({
                    message: "重置成功，请登录",
                    teleport: "#login-right",
                    className: "f-pa",
                });
            }
        } else if (code === 10052) {
            //账号封禁提示
            state.loading = false;
            state.isShowBannedTip = true;
            state.tipText = data;
        } else if (code === 10090) {
            //设备码被封禁
            Toast.fail({
                message: "该设备已被封禁",
                teleport: "#login-right",
                className: "f-pa",
            });
        }else if(code === 10061 || code === 10062){
            state.loading = false
            state.replayCheck = true
            Toast.fail({
                message: msg,
                teleport: "#login-right",
                className: "f-pa",
            });
            // return;
        } else if (msg) {
            Toast.fail({
                message: msg,
                teleport: "#login-right",
                className: "f-pa",
            });
            // state.phoneMsg = msg;
        }
    } catch (error) {
        console.log("error :>> ", error);
    }
    state.loading = false;
}
function count() {
    let curTime = 60,
        strTime = "",
        timer = setInterval(() => {
            if (curTime > 0) {
                --curTime;
                strTime = String(curTime).padStart(2, "0");
                state.codeText = strTime;
            } else {
                state.codeText = "重新发送";
                state.isDisabled = false;
                clearInterval(timer);
            }
        }, 1000);
}
async function verify() {
    if (isEmpty(state.mobile)) {
        Toast.fail({
            message: "手机号不得为空",
            teleport: "#login-right",
            className: "f-pa",
        });
        state.mobile = "";
        return false;
    }else if (!isPhone(state.mobile)) {
        Toast.fail({
            message: "手机号格式错误",
            teleport: "#login-right",
            className: "f-pa",
        });
        state.mobile = "";
        return false;
    }
    state.isDisabled = true;
    const { code, msg } = await fly.getSendMsg({
        mobile: state.mobile,
    });
    //console.log(data);
    if (!code) {
        count();
        return;
    }
    if(code===500){
        state.isDisabled = false
    }
    Toast.fail({
        message: msg,
        teleport: "#login-right",
        className: "f-pa",
    });
}
function tabSignType(type) {
    state.signType = type;
    state.loading = false;
}
const signCondition = computed(() => {
    if (state.signType === 0) {
        return !state.mobile || !state.code;
    } else if (state.signType === 1) {
        return !state.mobile || !state.password;
    } else if (state.signType === 2) {
        return !state.mobile || !state.code || !state.password;
    }
});

const buttonText = computed(() => {
    if (state.loading) {
        if (state.signType === 0 || state.signType === 1) return "登录中...";
        else return "重置密码...";
    } else {
        if (state.signType === 0) return "登录/注册";
        else if (state.signType === 1) return "登录";
        else return "重置密码";
    }
});
// store.logout()

defineExpose({ signType: toRef(state, "signType") });
</script>

<template>
    <section class="login-main" id="login-main">
        <!--<h1 class="f-pr sign-title f-fs22 f-cfff">
    
    <p>欢迎登录小C语音</p>
    <i class="f-fs14 f-op5">请使用小C语音账号登录</i>
</h1>-->
        <nav class="f-fx login-nav" :class="{ 'forget-pwd': state.signType === 2 }">
            <template v-if="state.signType === 0 || state.signType === 1">
                <p class="f-csp" :class="{ active: state.signType === 0 }" @click="tabSignType(0)">验证码登录</p>
                <p class="f-csp" :class="{ active: state.signType === 1 }" @click="tabSignType(1)">密码登录</p>
            </template>
            <template v-if="state.signType === 2">
                <p class="f-csp" :class="{ active: state.signType === 2 }" @click="tabSignType(2)">忘记密码</p>
                <div class="gap"></div>
                <p class="forget"><span @click="state.signType = 1">返回登录</span></p>
            </template>
        </nav>
        <div class="main-content" @keydown.enter="login">
            <a-input :placeholder="`请输入手机号${state.signType === 1?'/小CID':''}`" v-model="state.mobile" />

            <div class="sign-input f-dfc" v-if="state.signType === 0 || state.signType === 2 || state.replayCheck">
                <input type="text" @focus="state.isCls = false" maxlength="6" class="sign-code f-fx1 f-cfff" autocomplete="new-password" v-model="state.code" placeholder="请输入验证码" />
                <button class="sign-verify f-oun f-bt" :disabled="state.isDisabled" @click="verify">
                    {{ state.codeText }}
                </button>
            </div>
            <a-input :placeholder="state.signType === 1 ? '请输入密码' : '请输入新密码'" inputType="password" v-model="state.password" v-if="(state.signType === 1 && !state.replayCheck) || state.signType === 2" />
            <p class="f-op5 f-fs12 login-tip f-cfff" v-if="state.signType === 0">未注册的手机验证后将自动为您注册小C账号</p>
            <p class="forget" v-if="state.signType === 1"><span @click="state.signType = 2">忘记密码</span></p>
        </div>
        <div class="foot-content">
            <button class="f-w100 f-fs16 login-btn f-csp" @click="login" :disabled="signCondition">{{ buttonText }}</button>
            <!-- <p class="f-tac login-tip f-csp f-cfff" @click="$emit('goToRegister')">没有账号，去注册</p> -->
            <p>
                继续即表示同意
                <a href="https://app.cdddian.com/html/TreatyUsers.html" target="_blank">用户协议</a>
                和
                <a href="https://app.cdddian.com/html/TreatyUsers.html" target="_blank">隐私条款</a>
            </p>
        </div>
        <van-dialog class="f-cfff" v-model:show="state.isShowBannedTip" :show-confirm-button="false" :closeOnClickOverlay="true" teleport="body">
            <section class="banned-dialog">
                <img src="@/assets/login/login_ban.png" alt="" />
                <p class="title">账号被封禁</p>
                <p class="time">
                    您的账号由于<i class="f-cff0">{{ state.tipText.remarks }}</i>被禁止登录，解封时间为{{ state.tipText.endTime }}。如有疑义，请在{{ state.tipText.appealTime }}前联系客服申诉，超时将不再受理。
                </p>
                <p class="time">小C客服QQ：{{ state.tipText.customerQQ || 894411950 }}</p>
                <p class="time">在线时间：{{ state.tipText.workTime }}</p>
                <!-- <button class="input-pwd-btn confirm-pwd-btn f-db f-w30 f-csp" @click="state.isShowBannedTip = false">知道了</button> -->
            </section>
        </van-dialog>
    </section>
</template>
<style lang="less" scoped>
.banned-dialog {
    padding: 30px 20px;

    color: #aaaaaa;
    font-size: 12px;
    text-align: center;

    > img {
        width: 40px;
    }
    .title {
        font-weight: bold;
        margin-bottom: 10px;
    }
    .time {
        text-align: left;
    }

    // button {
    //     margin: 20px auto 0;
    // }
}
.login-tip {
    margin-top: 20px;
    margin-left: 4px;

    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
}
.login-nav {
    padding: 0 4px;
    &.forget-pwd {
        display: flex;
        align-items: flex-end;
    }
    p + p {
        margin-left: 20px;
    }
    p {
        line-height: 2;
        color: @color-white03;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;

        cursor: pointer;
        user-select: none;
        &::after {
            content: "";
            position: relative;
            top: 12px;
            display: none;

            width: 30px;
            height: 3px;
            margin: 0px auto;
            background: linear-gradient(135deg, #a283f9 0%, #7b4dfd 100%);
            border-radius: 2px;
        }
        &.forget {
            font-size: 14px;
            &:hover {
                color: #a97eff;
            }
        }
    }
    .gap {
        flex: auto;
    }
}
.login-nav > p.active {
    font-weight: 600;
    color: #a97eff;
    &::after {
        display: block;
    }
}

.sign-error {
    margin-bottom: 20px;
}
.sign-close {
    transform: translateY(3px);
}
.sign-code {
    width: 95%;
}
#login-right {
    position: relative;
}
.login-main {
    display: flex;
    flex-direction: column;

    width: 100%;
    min-width: 410px;
    padding: 48px 36px 24px 46px;
    .main-content {
        flex: auto;
        width: 100%;
        margin-top: 70px;
        .sign-input + .sign-input {
            margin-top: 24px;
            .sign-verify {
                color: #a97eff;
                font-weight: 400;

                cursor: pointer;
                user-select: none;
            }
        }

        .forget {
            margin-top: 20px;
            margin-right: 4px;
            text-align: right;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.3);
            span {
                cursor: pointer;
                &:hover {
                    color: #a97eff;
                }
            }
        }
    }
    .foot-content {
        text-align: center;
        .login-btn {
            max-width: 320px;
            height: 58px;

            font-size: 16px;
            color: rgba(255, 255, 255, 0.8);
            background: linear-gradient(135deg, #a283f9 0%, #7b4dfd 100%);
            border-radius: 45px;
            user-select: none;
            &:disabled {
                color: rgba(255, 255, 255, 0.3);
                cursor: not-allowed;
                background: linear-gradient(135deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
            }
        }
        > p {
            margin-top: 12px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.3);
            a {
                padding: 0 5px;
                color: #a97eff;
                cursor: pointer;
            }
        }
    }
}
</style>
