/**
 * MessageHandler接收TIM通知事件之后，通过mitt分发
 * useXXX 通过监听事件维护各自的state
 * 各组件调用各自的useXXX(),使用state
 */
import mitt from "mitt";
import { TYPES } from "@tencentcloud/chat"; // 消息类型
const emitter = mitt();

//在组件卸载时取消监听
emitter.once = function once() {
    this.on(...arguments);
    onBeforeUnmount(() => {
        this.off(...arguments);
    });
};

let stashRandom = [];//保存随机字段
//删除重复消息
function deleteRepeat(random) {
    if (!random) return false;
    if (stashRandom.includes(random)) return true;
    stashRandom.push(random);
    setTimeout(() => {
        stashRandom.shift();
    }, 15000)
    return false;
}

export default function MessageHandler(messageArr, currentGroupID) {
    messageArr.forEach(message => {
        console.log("%c onMessage :>> ", "font-size:20px;color:#0969da", message.conversationType + "-" + message.to, messageArr);

        switch (message.conversationType) {
            //系统消息-@TIM#SYSTEM
            case TYPES.CONV_SYSTEM: {
                break;
            }
            //社群消息-TOPIC
            case TYPES.CONV_TOPIC: {
                break;
            }
            //私聊消息-C2C
            case TYPES.CONV_C2C: {
                emitter.emit(TYPES.CONV_C2C, { message });
                break;
            }
            //聊天室群组消息-GROUP
            case TYPES.CONV_GROUP: {
                if (!message.to || (/\_\d+/.test(message.to) && (message.to !== currentGroupID))) return;//取消跨厅PK消息后端返回to字段和当前厅不匹配，暂时不做此判断 //2023-2-22测试已修复,已打开
                if (message.type === TYPES.MSG_CUSTOM && typeof message.payload.data === 'string') {
                    const msg = JSON.parse(message.payload.data);
                    console.log("msg :>> ", msg);
                    //if (deleteRepeat(msg.random)) break;
                    //触发通知事件
                    emitter.emit(msg.mType, { msg, message });
                }
                break;
            }
            default:
                break;
        }
    });
}

//消息监听
export function useEmitter() {
    return { emitter };
}

//milky-way-wishing许愿组件state
export function useWishing(init, countDownTimer) {
    const wishData = ref(null); //礼物数据
    const closedown = ref(0); //正在许愿倒计时秒数
    const isShow = ref(false); //许愿结果
    const trevFountainResult = ref({}); //获奖用户
    const isShowRank = ref(false);
    const rankList = ref([])
    //正在许愿倒计时
    function closePkWindow(duration) {
        closedown.value = duration || 10;
        const intervalId = setInterval(() => {
            closedown.value--;
            if (closedown.value < 1) {
                clearInterval(intervalId);
            }
        }, 1000);
    }
    //更新许愿礼物数据
    emitter.once("trevFountain", ({ msg }) => {
        delete msg.mType;
        if (wishData.value) {
            wishData.value = { ...wishData.value, ...msg };
        } else {
            wishData.value = msg;
        }
    });
    //开始许愿
    emitter.once("trevFountainDraw", ({ msg }) => {
        closePkWindow(msg.drawDuration);
        countDownTimer.pause();
    });
    //许愿结果
    emitter.once("trevFountainResult", ({ msg }) => {
        if(wishData.value && wishData.value.myProgress>0){//用户参与了许愿才显示结果
            isShow.value = true;
            trevFountainResult.value = msg;
        }
        init();
    });
    //获取实时榜单
    emitter.once('trevFountainBillBoard',({msg})=>{
        isShowRank.value = true
        rankList.value = msg.billBoardInfoList
    })
    return { wishData, closedown, isShow, trevFountainResult,isShowRank,rankList, emitter };
}
//space-exploration星界探索组件
export function useSpaceExploration(init) {
    return { emitter };
}

//ChatRank组件
export function useChatRank(currentRoomID) {
    //房间热度值
    const currentHot = ref(0);
    //更新热度值
    emitter.once("CtHeatValue", ({ msg }) => (currentHot.value = msg.hot));

    //实时贡献列表
    const chatRoomContributes = ref([]);
    emitter.once("CtOnlineList", ({ msg }) => {
        if (Array.isArray(msg.chatRoomContributes)) {
            chatRoomContributes.value = msg.chatRoomContributes;
        }
    });
    //显示、隐藏贡献值
    const chatRoomContributesStatus = ref(false);
    emitter.once("chatRoomOnlineListStatus", ({ msg }) => {
        if(currentRoomID.value==msg.roomId){
            chatRoomContributesStatus.value = Boolean(msg.status)
        }
    })
    return {
        currentHot,
        chatRoomContributes,
        chatRoomContributesStatus,
        emitter,
    };
}

//useChatRoomMsg组件
export function useChatRoomMsg(fn) {

    //房间公聊消息列表
    const roomMessageList = ref([]);
    const vipCard = ref('')

    function pushMsg({ msg, message }) {
        msg.msgId = (message ? message.ID : "") || Math.floor(Math.random() * 1e10);
        roomMessageList.value.push(msg);
        if (roomMessageList.value.length > 200) roomMessageList.value.shift();
    }

    //添加聊天消息
    emitter.once("Text", ({ msg, message }) => {
        pushMsg({ msg, message });
        typeof fn === "function" && fn()
    });

    //添加公聊图片消息
    emitter.once("Image", ({ msg, message }) => {
        pushMsg({ msg, message });
        typeof fn === "function" && fn()
    });

    //加入房间
    emitter.once("CtJoinRoom", ({ msg, message }) => {
        pushMsg({ msg, message });
    });
    //收藏房间
    emitter.once("CtCollection", ({ msg, message }) => {
        pushMsg({ msg, message });
    });

    //礼物公聊消息
    emitter.once("CtGift", ({ msg, message }) => {
        msg.receiveName = msg.dataList.chatGiftUserDto.receiveName//.map(item => item.receiveName).join(",");
        pushMsg({ msg, message });
    });

    //清理公屏
    emitter.once("clearScreen", ({ msg }) => {
        roomMessageList.value = [];
    });

    // let msg = {
    //     "level": 37,
    //     "charmLevel": 46,
    //     "description": "测试测试测试测试测试-测试测试测试测试测试-测试测试测试测试测试-测试测试测试测试",
    //     "type": 30,
    //     "sendUserHeadPortrait": "https://oss.cdddian.com/im/2022-12-01/17b1137f68364eec99e211f24117d5c6913527.jpg",
    //     "isVip": 0,
    //     "roomId": 15102,
    //     "mType": "barrage",
    //     "sendUserId": 1012385,
    //     "random": "llup4gmdc6",
    //     "vipLevel": 0,
    //     "bgType": 0,
    //     "sendUserName": "今天晴2",
    //     "id": "144115224427495691-1677208118-10767090998776452748"
    // }
    // window.addChat = () => {
    //     emitter.emit("barrage", { msg: { ...msg } });
    // }
    //使用vip卡
    emitter.once('CtUsedCard',({ msg, message })=>{
        // console.log('vip card:===>',msg);
        // store.vipCard = msg.cardImgUrl
        pushMsg({ msg, message });
        vipCard.value = msg.cardImgUrl
        const timer = setTimeout(() => {
            vipCard.value = '';
            clearTimeout(timer);
        }, 2e3);
    })
    //全服喇叭
    emitter.once("ChatRoomMessageScroll", ({ msg, message }) => {
        if (typeof msg.barrageType === "number" && msg.barrageType !== 1) return;
        if (typeof msg.bgType !== "number") return;
        msg.id = (message ? message.ID : "") + Math.floor(Math.random() * 1e10);
        roomMessageList.value.push(msg)
    });

    return {
        vipCard,
        roomMessageList,
        emitter,
    };
}

//ChatRoom组件
export function useChatRoom(store) {
    //被踢出房间
    emitter.once("CtUserOutRoom", ({ msg }) => {
        if (msg.processId && store.userId == msg.processId && store.currentRoomID == msg.roomId) {
            location.href = "/";
            Toast.fail("您已被踢出聊天室");
        }
    });
    //实时封禁账号
    emitter.once("platformMessageBannedUserNotice", async ({ msg }) => {
        if (msg.userList && msg.userList.includes(store.userId)) {
            Toast.fail("您已被封禁");
            await store.backendLogout();
            store.logout();
            location.href = "/";
        }
    });
    //游戏房切换
    /*emitter.once("gameRoomToggle", ({ msg }) => {
        if (msg.isGameRoom) {
            location.href = "/";
            Toast.fail("暂不支持加入该房间类型");
        }
    });*/
    //相亲厅匹配成功
    emitter.once("chatBlindDateMatchingSuccess", ({ msg }) => {
        // console.log('chatBlindDateMatchingSuccess :>> ', msg);
        if (msg.blindDateChooseResult) {
            store.srcUrls.push(msg);  //播放礼物视频动画
        }
    });
    
    //更新麦位信息
    emitter.once("CtChangeMic", ({ msg }) => {
        store.currentRoomInfo.chatHosts = msg.chatHosts;
    });
    //更新五麦位模式
    emitter.once("radioStationMessage", ({ msg }) => {
        store.currentRoomInfo.radioMode = msg.radioMode
    });

    //魔法表情
    emitter.once("CtSendDynamicExpression", ({ msg }) => {
        const chatHost = store.currentRoomInfo.chatHosts[msg.serialNumber];
        if (!chatHost) return;
        chatHost.emojiUrl = msg.url;
        chatHost.dynamicId = msg.dynamicId;
        chatHost.emojiId = msg.dynamicId + Date.now();
    });
    //重置麦位礼物值
    emitter.once("CtResetGiftScore", ({ msg }) => {
        store.currentRoomInfo.chatHosts = msg.chatHosts;
    });
    //开关排行榜
    emitter.once("auctionMessage", ({ msg }) => {
        store.currentRoomInfo.auction = msg.type;
    });
    //排行榜信息
    emitter.once("auction", ({ msg }) => {
        store.currentRoomInfo.auctionInfo = msg.data;
    });
    //开启厅内PK
    emitter.once("internalPk", ({ msg }) => {
        msg.data.pkBaseTime = Math.round(performance.now());
        store.currentRoomInfo.appInternalActivity = msg.data;
    });
    //关闭厅内PK
    emitter.once("endInternalPk", ({ msg }) => {
        msg.data.pkBaseTime = Math.round(performance.now());
        msg.data.ended = true;
        store.currentRoomInfo.appInternalActivity = msg.data;
    });
    /*电台厅连麦PK
    emitter.once("chatRoomRadioStationApplyForPk", ({ msg }) => {
        if (msg.roomId === store.currentRoomID && store.myRole > 1) {
            msg.id = String(msg.systemTime) + Math.floor(Math.random() * 1e5);
            msg.pkBaseTime = Math.round(performance.now());
            store.roomPkInfo = msg;
        }
    });*/
    //请求跨厅PK
    emitter.once("chatRoomApplyForPk", ({ msg }) => {
        //console.log(msg.roomId , store.currentRoomID);
        if (msg.roomId == store.currentRoomID && store.myRole > 1) {
            msg.id = String(msg.systemTime) + Math.floor(Math.random() * 1e5);
            msg.pkBaseTime = Math.round(performance.now());
            store.roomPkInfo = msg;
        }
    });
    //取消跨厅PK
    emitter.once("chatRoomCancelPk", ({ msg }) => {
        store.roomPkInfo = {};
    });
    //拒绝跨厅PK
    emitter.once("chatRoomRejectPk", ({ msg }) => {
        store.roomPkInfo = {};
    });
    //开始跨厅PK //更新跨厅PK信息
    emitter.once("chatRoomPk", ({ msg }) => {
        if (msg.roomId == store.currentRoomID) {
            store.roomPkInfo = {};
            msg.data.pkBaseTime = Math.round(performance.now());
            store.currentRoomInfo.appChatPk = msg.data;
        }
    });
    //红蓝pk
    emitter.once('chatRoomCampPk',({ msg })=>{//开始
        // console.log('红蓝pk开始：',msg);
        store.isShowPkEgg = msg.appCampActivityDto.stage //显示彩蛋
        store.currentRoomInfo.activityType = 4
        store.currentRoomInfo.isEndEarly = false
        store.currentRoomInfo.appRedAndBluePk = null
        store.currentRoomInfo.appCampAndRankInfoDto = msg.appCampAndRankInfoDto 
        store.currentRoomInfo.appCampActivityDto = msg.appCampActivityDto
        store.openPkResultDialog = false
    })
    emitter.once('chatRoomCampRewardResult',({ msg })=>{//正常结束
        // console.log('红蓝pk结束：',msg);
        store.isShowPkEgg = false
        store.isEndEarly = false
        store.currentRoomInfo.appRedAndBluePk = msg
        store.currentRoomInfo.appCampAndRankInfoDto = msg.appCampAndRankInfoDto
        store.currentRoomInfo.currentRedUserList = msg.currentRedUserList
        store.currentRoomInfo.currentBlueUserList = msg.currentBlueUserList
        store.openPkResultDialog = true
    })
    emitter.once('chatRoomCampEndPk',({ msg })=>{//提前结束
        // console.log('红蓝pk提前结束：',msg);
        store.isEndEarly = true//提前结束
        store.isShowPkEgg = false
        store.currentRoomInfo.appRedAndBluePk = null
        store.currentRoomInfo.currentRedUserList = msg.currentRedUserList
        store.currentRoomInfo.currentBlueUserList = msg.currentBlueUserList
        store.openPkResultDialog = true
    })
    emitter.once('chatRoomEggAnimation',({ msg })=>{//彩蛋播放特效
        // console.log('彩蛋播放：',msg);
        if(msg.eggSpecialEffects){
            store.srcUrls.push(msg.eggSpecialEffects);
        }
    })

    /*开始、更新电台厅连麦PK信息
    emitter.once("chatRoomRadioStationPk", ({ msg }) => {
        if (msg.roomId === store.currentRoomID) {
            store.roomPkInfo = {};
            msg.data.pkBaseTime = Math.round(performance.now());
            store.currentRoomInfo.appChatPk = msg.data;
        }
    });
    */
    //结束跨厅PK
    emitter.once("endChatRoomPk", ({ msg }) => {
        if (msg.roomId == store.currentRoomID) {
            store.roomPkInfo = {};
            store.currentRoomInfo.appChatPk.ended = true;
            store.currentRoomInfo.appChatPk.endedInfo = msg;
        }
    });
    /*结束电台厅连麦PK
    emitter.once("endChatRoomRadioStationPk", ({ msg }) => {
        if (msg.roomId === store.currentRoomID) {
            store.roomPkInfo = {};
            store.currentRoomInfo.appChatPk.ended = true;
            store.currentRoomInfo.appChatPk.endedInfo = msg;
        }
    });
    */
    //礼物
    const giftMiddleBar = ref(null);
    const roomGiftList = ref([]);

    emitter.once("CtGift", ({ msg, message }) => {
        try {
            if (msg && msg.dataList) {
                const {dataList,chatGiftUserDto:{receiveName}} = msg.dataList;
                msg.showReceiveName = receiveName; //msg.dataList.length === 1 ? : "多麦";
                const clientTime = message.clientTime * 1000
                msg.clientTime = clientTime;
                const giftList = dataList.map(el=>{
                    el.clientTime = clientTime;
                    el.nickname = msg.nickname
                    el.sendUserHeadPortrait = msg.sendUserHeadPortrait
                    el.receiveName = receiveName
                    msg.giftCount = el.giftCount
                    msg.giftName = el.giftName
                    if(el.giftEffect && store.disabledEffect==0){//礼物特效启用状态添加到播放队列
                        store.srcUrls.push(el.giftEffect);
                    }
                    return el
                })
                roomGiftList.value.unshift(...giftList);
                if (roomGiftList.value.length > 100) roomGiftList.value.pop();
                
                giftMiddleBar.value = msg;
            }
    
            // if (msg.giftEffect) {
            // }
            if (Array.isArray(msg.chatHosts)) {
                store.currentRoomInfo.chatHosts = msg.chatHosts;
            }
        } catch (error) {
            console.error('送礼报错：===>',error)
        }
    });

    //心网关系达成
    //emitter.once("ctUserRelationshipAgree", ({ msg }) => { });
    //修改房间配置
    emitter.once("CtChangeConfig", ({ msg }) => {
        if (store.currentRoomID == msg.roomId) {
            store.currentRoomInfo.background = msg.background;
            store.currentRoomInfo.greeting = msg.greeting;
            store.currentRoomInfo.theme = msg.theme;
            store.currentRoomInfo.microphoneWay = msg.microphoneWay;
            store.currentRoomInfo.picPublicSendSwitch = msg.picPublicSendSwitch;
            store.currentRoomInfo.lowLevelMsgPublicSendSwitch = msg.lowLevelMsgPublicSendSwitch;
        }
    });
    //修改房间配置
    emitter.once("CtChangeChatType", ({ msg }) => {
        if (store.currentRoomID == msg.roomId) {
            store.currentRoomInfo.chatHosts = msg.chatHosts;
            store.currentRoomInfo.chatType = msg.chatType;
        }
    });
    //设置管理员
    emitter.once("SetAdmin", ({ msg }) => {
        if (msg.processId && msg.processId == store.userId) {
            store.currentRoomInfo.role = "Admin";
            store.myRole = store.roleMap[store.currentRoomInfo.role]
        }
    });
    //设置超级管理员
    emitter.once("SetSuperAdmin", ({ msg }) => {
        if (msg.processId && msg.processId == store.userId) {
            store.currentRoomInfo.role = "SuperAdmin";
            store.myRole = store.roleMap[store.currentRoomInfo.role]
        }
    });
    //取消管理员
    emitter.once("CancelAdmin", ({ msg }) => {
        if (msg.processId && msg.processId == store.userId) {
            store.currentRoomInfo.role = "Member";
            store.myRole = store.roleMap[store.currentRoomInfo.role]
        }
    });
    //申请上麦数量
    const applyNumber = ref(0);
    emitter.once("applyForMicrophoneList", ({ msg }) => {
        applyNumber.value = msg.count;
    });
    //用户等级升级
    emitter.once("levelUpgradePromptUser", ({ msg }) => {
        // console.log('用户升级：===>',msg);
        if(msg.userId == store.userId){
            store.setUserInfoPart(msg)
        }
    })
    return {
        applyNumber,
        giftMiddleBar,
        roomGiftList,
        emitter,
    };
}

//房间通知
export function useRoomNote() {
    return { emitter };
}

//礼物弹幕
export function useGiftBar() {
    const chatBarrageList = ref([]); //100以上礼物弹幕
    const chatplatformBarrageList = ref([]); //1000以上礼物平台弹幕
    const chatBlindDateBarrageList = ref([]); //相亲厅弹幕
    const stashchatBarrageList = []; //超出数量的弹幕暂时保存
    const stashchatplatformBarrageList = []; //超出数量的弹幕暂时保存
    const nobleBarrageList = ref([]);//贵族弹幕
    const pkBarrageList = ref([]);//红蓝弹幕

    function addBarrage(msg) {
        chatBarrageList.value.unshift(msg);
        setTimeout(() => {
            const index = chatBarrageList.value.indexOf(msg);
            if (index !== -1) chatBarrageList.value.splice(index, 1);
            if (stashchatBarrageList.length) addBarrage(stashchatBarrageList.shift());
        }, msg.duration * 1000 || 10000);
    }
    function addPlatformBarrage(msg) {
        chatplatformBarrageList.value.unshift(msg);
        setTimeout(() => {
            const index = chatplatformBarrageList.value.indexOf(msg);
            if (index !== -1) chatplatformBarrageList.value.splice(index, 1);
            if (stashchatplatformBarrageList.length) addPlatformBarrage(stashchatplatformBarrageList.shift());
        }, msg.duration * 1000 || 15000);
    }
    function addBlindDateBarrage(msg) {
        chatBlindDateBarrageList.value.unshift(msg);
        setTimeout(() => {
            const index = chatBlindDateBarrageList.value.indexOf(msg);
            if (index !== -1) chatBlindDateBarrageList.value.splice(index, 1);
            if (stashchatplatformBarrageList.length) addPlatformBarrage(stashchatplatformBarrageList.shift());
        }, msg.duration * 1000 || 15000);
    }
    function addNobleBarrage(msg) {
        nobleBarrageList.value.unshift(msg);
        setTimeout(() => {
            const index = nobleBarrageList.value.indexOf(msg);
            if (index !== -1) nobleBarrageList.value.splice(index, 1);
            if (stashchatplatformBarrageList.length) addNobleBarrage(stashchatplatformBarrageList.shift());
        }, msg.duration * 1000 || 15000);
    }
    function addPkBarrage(msg) {
        pkBarrageList.value.unshift(msg);
        setTimeout(() => {
            const index = pkBarrageList.value.indexOf(msg);
            if (index !== -1) pkBarrageList.value.splice(index, 1);
            if (stashchatplatformBarrageList.length) addPkBarrage(stashchatplatformBarrageList.shift());
        }, msg.duration * 1000 || 15000);
    }
    //一般礼物公告
    emitter.once("ChatBarrage", ({ msg, message }) => {
        msg.id = (message ? message.ID : "") + Math.floor(Math.random() * 1e10);
        if (chatBarrageList.value.length < 2) {
            addBarrage(msg);
        } else {
            stashchatBarrageList.push(msg);
        }
    });
    //大礼物公告
    emitter.once("platformMessageGiftBarrage", ({ msg, message }) => {
        msg.id = (message ? message.ID : "") + Math.floor(Math.random() * 1e10);

        if (chatplatformBarrageList.value.length < 2) {//
            addPlatformBarrage(msg);
        } else {
            stashchatplatformBarrageList.push(msg);
        }
    });
    //开通或续费贵族公告
    emitter.once("platformMessageNobleBarrage", ({ msg, message }) => {
        msg.id = (message ? message.ID : "") + Math.floor(Math.random() * 1e10);

        if (nobleBarrageList.value.length < 2) {
            addNobleBarrage(msg);
        } else {
            stashchatplatformBarrageList.push(msg);
        }
    });
    //相亲厅全服公告
    emitter.once("platformMessageBlindDateAllNotice", ({ msg, message }) => {
        msg.id = (message ? message.ID : "") + Math.floor(Math.random() * 1e10);

        if (chatBlindDateBarrageList.value.length < 2) {
            addBlindDateBarrage(msg);
        } else {
            stashchatplatformBarrageList.push(msg);
        }
    });
    //红蓝pk全服公告
    emitter.once('chatRoomMessageCampPkNotice',({ msg, message }) => {
        msg.id = (message ? message.ID : "") + Math.floor(Math.random() * 1e10);

        if (pkBarrageList.value.length < 2) {
            addPkBarrage(msg);
        } else {
            stashchatplatformBarrageList.push(msg);
        }
    });
    // let msg = {
    //     unitPrice: 131400,
    //     receiveUserHeadPortrait: "https://oss.cdddian.com/im/config/%E9%BB%98%E8%AE%A4%E5%A4%B4%E5%83%8F.png",
    //     showRoomId: 15079,
    //     giftName: "雪国恋歌",
    //     giftCount: 1,
    //     receiveUserName: "今天晴2",
    //     sendUserHeadPortrait: "https://oss.cdddian.com/im/config/%E9%BB%98%E8%AE%A4%E5%A4%B4%E5%83%8F.png",
    //     roomId: 15079,
    //     mType: "platformBarrage",
    //     sendUserId: 1012382,
    //     duration: 10,
    //     giftId: 278,
    //     random: "zx4urk7939",
    //     giftImg: "https://oss.cdddian.com/im/2022-07-22/42f3dd29b7cf43c89212a6b4917817d5497100.png",
    //     receiveUserId: 1012385,
    //     giftPrice: 131400,
    //     sendUserName: "今天晴",
    //     theme: "相亲方法",
    //     id: "144115224427495691-1660032123-6658379573050122133",
    // };
    // window.add = () => {
    //     emitter.emit("platformBarrage", { msg: { ...msg } });
    // };
    // let msg1 = {
    //     unitPrice: 52000,
    //     receiveUserHeadPortrait: "https://oss.cdddian.com/im/config/%E9%BB%98%E8%AE%A4%E5%A4%B4%E5%83%8F.png",
    //     giftName: "爱心轰炸机",
    //     giftCount: 1,
    //     receiveUserName: "今天晴2",
    //     sendUserHeadPortrait: "https://oss.cdddian.com/im/config/%E9%BB%98%E8%AE%A4%E5%A4%B4%E5%83%8F.png",
    //     roomId: 15079,
    //     mType: "ChatBarrage",
    //     sendUserId: 1012382,
    //     duration: 10,
    //     giftId: 277,
    //     giftImg: "https://oss.cdddian.com/im/2022-07-22/7d8ab238edda4734b3ab4654caad3cd7866980.png",
    //     receiveUserId: 1012385,
    //     giftPrice: 52000,
    //     sendUserName: "今天晴",
    //     id: "144115224427495691-1660032391-15258314332639731662",
    // };
    // window.add1 = () => {
    //     emitter.emit("ChatBarrage", { msg: { ...msg1 } });
    // };

    return { chatBarrageList, chatplatformBarrageList,nobleBarrageList,chatBlindDateBarrageList,pkBarrageList };
}
//星际探索动图弹幕
export function userSpaceBarrage() {
    const spaceBarrageList = ref([]); //星际弹幕
    const stashBarrage = []; //超出数量的弹幕暂时保存

    function add(msg) {
        spaceBarrageList.value.unshift(msg);
        setTimeout(() => {
            const index = spaceBarrageList.value.indexOf(msg);
            if (index !== -1) spaceBarrageList.value.splice(index, 1);
            if (stashBarrage.length) add(stashBarrage.shift());
        }, 15000);
    }

    emitter.once("ChatRoomMessageSpatioExploreNotice", ({ msg, message }) => {//barrage
        if (typeof msg.barrageType !== "number" || msg.barrageType < 2) return;
        msg.id = (message ? message.ID : "") + Math.floor(Math.random() * 1e10);
        if (spaceBarrageList.value.length < 2) {
            add(msg);
        } else {
            stashBarrage.push(msg);
        }
    });

    // let msg = {
    //     description: "天降鸿运！今***在星际探索中发现了裂缝礼物3*1",
    //     type: 10,
    //     barrageType: 4,
    //     sendUserHeadPortrait: "https://oss.cdddian.com/im/2021-04-22/1619055972768.png",
    //     roomId: 15079,
    //     mType: "barrage",
    //     sendUserId: 7,
    //     duration: 1,
    //     random: "wgcvjadxct",
    //     bgType: 1,
    //     itemName: "裂缝礼物3",
    //     giftIcon: "https://oss.cdddian.com/im/2021-03-07/1c65dbd2cd9c47d6b5bebdedd5ba6e16321747.png",
    //     nickname: "今天晴",
    //     sendUserName: "星际小顽皮",
    // };
    // window.add2 = () => {
    //     emitter.emit("barrage", { msg: { ...msg } });
    // };

    return { spaceBarrageList };
}

//普通滚动弹幕
export function userBarrageNormal() {
    const barrageListNormal = ref([]); //bgType 0.全服喇叭 1.星际探索 2.普通公告 3.许愿池奖励 4.幸运夺宝 5.贵族公爵弹幕 6.贵族郡王弹幕 7.贵族国王弹幕
    function pushMsg({ msg, message }) {
        if (typeof msg.barrageType === "number" && msg.barrageType !== 1) return;
        if (typeof msg.bgType !== "number") return;
        msg.id = (message ? message.ID : "") + Math.floor(Math.random() * 1e10);
        msg.top = Math.floor(Math.random() * 100);
        barrageListNormal.value.push(msg);
    }
    emitter.once("ChatRoomMessageScroll", pushMsg);//barrage
    emitter.once('ChatRoomMessageTrevFountainNotice',pushMsg);
    //     var msg = {
    //         description: "天降鸿运！今***在星际探索中发现了一鹿有你*1",
    //         type: 10,
    //         barrageType: 1,
    //         sendUserHeadPortrait: "https://oss.cdddian.com/im/2021-04-22/1619055972768.png",
    //         roomId: 15079,
    //         mType: "barrage",
    //         sendUserId: 7,
    //         duration: 1,
    //         random: "3j2sx7tpp0",
    //         bgType: 1,
    //         itemName: "一鹿有你",
    //         giftIcon: "https://oss.cdddian.com/im/2021-11-25/4f7a29ebaa8340c1ad8f1d731ae171a6703251.png",
    //         nickname: "今天晴",
    //         sendUserName: "星际小顽皮",
    //     };
    //     var msg = {
    //         sendUserId: 8,
    //         random: "f9nmsg7ti1",
    //         bgType: 3,
    //         sendUserName: "星河许愿",
    //         description: "今天晴2在星河许愿获得了花团簇拥*1",
    //         type: 10,
    //         sendUserHeadPortrait: "https://oss.cdddian.com/im/2021-05-11/8eac0c8ae1cf46abb59a6319cea3b443292123.png",
    //         roomId: 15036,
    //         mType: "barrage",
    //         id: "144115224427495691-1660701335-103581806209282100",
    //     };
    //     window.add3 = bgType => {
    //         emitter.emit("barrage", { msg: { ...msg, ...(typeof bgType === "number" ? { bgType } : {}) } });
    //     };

    return { barrageListNormal };
}

//财富魅力升级特效
export function useLevelUp() {
    const levelUpList = ref([]); //财富魅力等级提升
    function pushMsg({ msg, message }) {
        msg.id = (message ? message.ID : "") + Math.floor(Math.random() * 1e10);
        msg.order = msg.type === 1 ? msg.level : msg.charmLevel;
        levelUpList.value.push(msg);
        setTimeout(() => {
            const index = levelUpList.value.indexOf(msg);
            if (index !== -1) levelUpList.value.splice(index, 1);
        }, msg.duration * 1000 || 5000);
    }

    emitter.once("levelUpgradePromptRoom", pushMsg);
    emitter.once("ChatRoomMessageLevelUpgradePrompt", pushMsg);

    return { levelUpList };
}
/*藏宝阁弹幕*/
export function useTreasureHouse(){
    const barrageList = ref([])
    //更新弹幕
    emitter.once("treasureBarrageList",({msg})=>{
        barrageList.value = msg.barrageList
    })
    return {barrageList}
}
//摘星阁弹幕
export function usePickStar(){
    const barrageList = ref([])
    const pickStarInfo = ref({})
    //更新弹幕
    emitter.once("startsMessageBarrageList",({msg})=>{
        barrageList.value = msg.barrageList
    })
    //更新道具
    emitter.once("startsMessageGetPrizePoolInfo",({msg})=>{
        pickStarInfo.value = msg
    })
    return {barrageList,pickStarInfo}
}
// export class CEvent {
//     static INTERACT = "interact";
// }
